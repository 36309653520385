//
// import { useState } from "react";
import "./App.css";
// import SubmitForm from "./Components/FormSubmit/SubmitForm";
// import Card from "./Components/Card/Card";
import Routing from "./Routing";

function App() {
  return(
    <Routing/>
  )
}

export default App;
